import PropTypes from "prop-types";
import React from "react";
import classnames from "classnames";

import Header from "./header";

function Layout({ children, fullWidth }) {
  return (
    <div className="flex flex-col font-sans min-h-screen bg-cream text-dark">
      <Header />

      <main
        className={classnames("flex flex-col flex-1 w-full", {
          "max-w-6xl mx-auto px-4 py-8 md:p-8": !fullWidth
        })}
      >
        {children}
      </main>
      <div className="bg-dark-lighter text-white px-4 py-2 text-center uppercase tracking-wider text-xs font-bold">
        Website designed and built by{" "}
        <a
          href="https://amandakievet.com/"
          className="underline hover:no-underline"
        >
          Amanda Kievet
        </a>
      </div>
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
