export default [
  {
    route: `/about`,
    title: `About`
  },
  {
    title: `History`,
    sublinks: [
      {
        route: "/union-chapel",
        title: "Union Chapel"
      },
      {
        route: "/school-house",
        title: "School House"
      }
    ]
  },
  {
    title: `Community`,
    sublinks: [
      {
        route: "/events",
        title: "Events"
      },
      {
        route: "/blog",
        title: "Blog"
      }
    ]
  },
  {
    title: `Resiliency`,
    sublinks: [
      {
        route: "/about-cro",
        title: "About CRO"
      },
      {
        route: "/projects",
        title: "2020 Projects"
      },
      {
        route: "/emergency-preparedness",
        title: "Emergency Preparedness"
      }
    ]
  },
  {
    route: `/covid-19`,
    title: `Covid 19`,
    highlight: true
  }
];
