import { graphql, useStaticQuery, Link } from "gatsby";
import React, { useState } from "react";
import classnames from "classnames";

import navData from "../data/navigation";
import iconDown from "../images/icons/caret-down.svg";
import iconUp from "../images/icons/caret-up.svg";

const NavItem = ({
  route,
  title,
  highlight,
  sublinks,
  index,
  expandedSubNav,
  expandSubNav
}) => {
  const topLevelCls = "block md:inline-block mt-4 md:mt-0 md:ml-8";
  const mainTextCls =
    "no-underline text-sm font-semibold uppercase tracking-wider";

  if (sublinks) {
    return (
      <div className={`${topLevelCls} relative`}>
        <button
          className={`${mainTextCls} focus:outline-none`}
          onClick={() => expandSubNav(expandedSubNav === index ? null : index)}
        >
          {title}{" "}
          <img
            src={expandedSubNav === index ? iconUp : iconDown}
            alt="icon expand"
            className="inline w-2"
          />
        </button>
        <ul
          className={classnames(
            "md:absolute mt-1 bg-cream px-4 pt-4 pb-2 md:shadow md:bg-cream-light md:w-48 z-10",
            {
              hidden: expandedSubNav !== index
            }
          )}
        >
          {sublinks.map(({ route, title }) => (
            <li key={title} className="leading-tight mb-4 ">
              <Link
                key={title}
                to={route}
                className="text-sm border-b-2 border-transparent hover:border-sage"
              >
                {title}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    );
  } else {
    return (
      <Link
        className={classnames(
          `${mainTextCls} ${topLevelCls} hover:border-b-2`,
          {
            "text-carmine": highlight
          }
        )}
        to={route}
      >
        {title}
      </Link>
    );
  }
};

function Header() {
  const [isExpanded, toggleExpansion] = useState(false);
  const [expandedSubNav, expandSubNav] = useState(null);

  const { site } = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <header>
      <div className="flex flex-wrap md:flex-col lg:flex-row items-center justify-between max-w-6xl mx-auto p-4">
        <Link className="flex items-center no-underline md:mb-4 lg:mb-0" to="/">
          <span className="text-xl md:text-2xl font-serif">
            {site.siteMetadata.title}
          </span>
        </Link>

        <button
          className="block md:hidden border flex items-center px-3 py-2 rounded text-dark focus:outline-none"
          onClick={() => toggleExpansion(!isExpanded)}
        >
          <svg
            className="fill-current h-3 w-3"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>

        <nav
          className={`${
            isExpanded ? `block` : `hidden`
          } md:block md:flex w-full md:w-auto items-center border-b-2  md:border-none pb-4 md:pb-0`}
        >
          {navData.map((link, index) => (
            <NavItem
              key={link.title}
              {...link}
              expandedSubNav={expandedSubNav}
              expandSubNav={expandSubNav}
              index={index}
            />
          ))}
        </nav>
      </div>
    </header>
  );
}

export default Header;
